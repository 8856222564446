import {
  Text,
  Stack,
  Heading,
  PageBlock,
  Divider,
  Card,
} from 'braid-design-system';
import { Fragment } from 'react';

import type { accountPartsFragment } from 'src/graphql/generated';
import type { AccountSelectionResults } from 'src/types';
import type { PendingAccountEmailStatuses } from 'src/views/pages/SelectAccountPage/SelectAccountPage';

import { AccountItem } from './AccountItem/AccountItem';

interface AccountSegmentProps {
  heading: string;
  subheading: string;
  accounts: AccountSelectionResults['edges'];
  handleSelectAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  handleActivateAccount?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: accountPartsFragment,
  ) => void;
  pendingAccountEmailStatuses?: PendingAccountEmailStatuses;
}

const AccountSegment = ({
  heading,
  subheading,
  accounts,
  handleSelectAccount,
  handleActivateAccount,
  pendingAccountEmailStatuses,
}: AccountSegmentProps) => (
  <PageBlock width="medium">
    <Stack space="large">
      <Stack space="small">
        {heading ? (
          <Heading level="4" component="h3">
            {heading}
          </Heading>
        ) : null}
        {subheading ? <Text>{subheading}</Text> : null}
      </Stack>
      <Card>
        <Stack space="large">
          {/* <Divider /> */}
          {accounts.map(({ node }) => (
            <Fragment key={node.hirerAccountId}>
              {/* Parent */}
              <AccountItem
                option={node}
                handleSelectAccount={handleSelectAccount}
                handleActivateAccount={handleActivateAccount}
                pendingAccountEmailStatuses={pendingAccountEmailStatuses}
              />

              {/* Children */}
              {node.childAccounts?.map((childAccountOption) => (
                <AccountItem
                  option={childAccountOption}
                  handleSelectAccount={handleSelectAccount}
                  key={childAccountOption.hirerAccountId}
                />
              ))}
              <Divider />
            </Fragment>
          ))}
        </Stack>
      </Card>
    </Stack>
  </PageBlock>
);

export default AccountSegment;
