import { useTranslations } from '@vocab/react';
import { Heading, Stack, Text } from 'braid-design-system';

import translations from 'src/views/pages/RegistrationPage/.vocab';
import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';

const RegistrationHeader = () => {
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  if (context.loading) {
    return;
  }

  const { numberOfAccounts } = context;

  const hasAnotherAccount = numberOfAccounts > 0;

  return (
    <Stack space="xlarge">
      <Heading level="2">
        {hasAnotherAccount
          ? t('Create new employer account')
          : t('Your employer account')}
      </Heading>
      {!hasAnotherAccount && (
        <Stack space="medium">
          <Text>{t('Almost done')}</Text>
          <Text>{t('We need some details')}</Text>
        </Stack>
      )}
    </Stack>
  );
};

export default RegistrationHeader;
