import { useTranslations } from '@vocab/react';
import { FieldLabel, Stack, Text, TextField } from 'braid-design-system';
import type { FocusEvent } from 'react';

import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import { FormSection } from 'src/views/shared/forms/RegistrationForm/FormSection/FormSection';

import useFields from './usePersonalDetailsFields';

interface Props {
  handleFieldBlur: (event: FocusEvent<HTMLInputElement>) => void;
  handleFieldFocus: (event: FocusEvent<HTMLInputElement>) => void;
}

const PersonalDetailsCard = ({ handleFieldFocus, handleFieldBlur }: Props) => {
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  const { firstName, lastName } = useFields();

  if (context.loading) {
    return null;
  }

  return (
    <FormSection
      heading={t('Your details')}
      description={t('We need a real name')}
    >
      <Stack space="large">
        <Stack space="small">
          <FieldLabel htmlFor="emailDisplay" label={t('Email')} />
          <Text data={{ 'dd-privacy': 'mask' }} id="emailDisplay">
            {context.identity.subject.email}
          </Text>
        </Stack>
        <Stack space="small">
          <TextField
            id="firstName"
            label={t('Given name')}
            data={{ 'tracking-id': 'first-name' }}
            value={firstName.value}
            tone={firstName.errorMessage ? 'critical' : undefined}
            message={firstName.errorMessage}
            onChange={firstName.onChange}
            onFocus={handleFieldFocus}
            onBlur={(e) => {
              handleFieldBlur(e);
              firstName.onBlur();
            }}
            reserveMessageSpace
          />
          <TextField
            id="lastName"
            label={t('Family name')}
            data={{ 'tracking-id': 'last-name' }}
            value={lastName.value}
            tone={lastName.errorMessage ? 'critical' : undefined}
            message={lastName.errorMessage}
            onChange={lastName.onChange}
            onFocus={handleFieldFocus}
            onBlur={(e) => {
              handleFieldBlur(e);
              lastName.onBlur();
            }}
            reserveMessageSpace
          />
        </Stack>
      </Stack>
    </FormSection>
  );
};

export default PersonalDetailsCard;
